<template>
    <div class="workPlan">
        <div class="searct-list">
            <div class="person">
                <person v-bind="personObj3" @getData="participatePerson2"></person>
            </div>
        </div>

        <div class="table-container">
            <div class="table-header">
                <div class="title-row first-cell" style="width: 80px;">
                    <div style="text-align: right;padding-right: 5px;line-height: 18px">日期</div>
                    <div style="padding-left: 3px;line-height: 18px">名字</div>
                </div>
                <div class="thead-td" v-for="item in date">{{item}}</div>
            </div>
            <div class="table-content">
                <div class="table-header" v-for="(item,index) in list" :style="index == list.length-1 ? 'border-bottom: 1px solid #ddd' : ''">
                    <div class="title-row tab-name" style="width: 80px;">李泳章</div>
                    <div class="thead-td" :class="['row-'+item,isCheck ? 'is-check' : '',temporary.includes(index+'-'+tabIndex)? 'chekced' : '']" @click="_check(index,tabIndex)" v-for="(item,tabIndex) in date"></div>
                </div>
            </div>
        </div>

        <a-modal title="添加工作计划" :visible="addWorkVisible" @ok="handleOk" @cancel="handleCancel">
            <div>
                <a-textarea placeholder="请输入" v-model="textareaVal" style="resize: none" :rows="4" />
            </div>
        </a-modal>
    </div>
</template>

<script>
import person from "@/components/SelectPersonnel/SelectPersonnel";
export default {
    name: "workPlan",
    components: {
        person
    },
    data(){
        return {
            date: 0,
            list: [{}, {}],
            temporary: [],
            textareaVal: '',
            isCheck: false,
            addWorkVisible: false,
            personObj3: {
                rank: 1, //职级 0为不选，1为选
                nums: 1, //选的数量
                department: 1, //0不选部门，1为都选（人 部门都选），2为只选部门，选人
                contacts: "", //常用联系人 不要常用联系人传空''
                selectArr: [], //要传入的数据
                placeholder: "搜索名称"
            },
        }
    },
    created() {
        this.date = this.mGetDate();
        this.keyDown();
    },
    methods: {
        handleOk(){
            
        },
        handleCancel(){
            this.addWorkVisible = false;
            this.temporary = [];
        },
        _check(index,tabIndex){
            this.temporary.push(index+'-'+tabIndex);
        },
        keyDown() {
            // 键盘按下事件
            document.onkeydown = (e) => {
                // 取消默认事件
                e.preventDefault();
                //事件对象兼容
                let e1 = e || event || window.event || arguments.callee.caller.arguments[0]
                //键盘按键判断:左箭头-37;上箭头-38；右箭头-39;下箭头-40  回车：13   ctrl：17   shift：16
                switch (e1.keyCode) {
                    case 16:
                        console.log('按下了shift')
                        break;
                    case 17:
                        this.isCheck = true;
                        console.log(e.target)
                        break;
                }
            }
            // 键盘抬起事件
            document.onkeyup = (e) => {
                // 取消默认事件
                e.preventDefault();
                //事件对象兼容
                let e1 = e || event || window.event || arguments.callee.caller.arguments[0]
                switch (e.keyCode) {
                    case 16:
                        console.log('松开了shift');
                        break;
                    case 17:
                        this.isCheck = false;
                        if (this.temporary.length != 0) {
                            this.addWorkVisible = true;
                        }
                        break;
                }
            }
        },
        mGetDate(){
            var date = new Date();
            var year = date.getFullYear();
            var month = date.getMonth()+1;
            var d = new Date(year, month, 0);
            return d.getDate();
        },
        participatePerson2(value) {

        },
    }
}
</script>

<style scoped lang="less">
@import "./WorkPlan";
</style>